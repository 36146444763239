import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js"
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg"
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg"

import MTPortfolio01 from "images/mt_portfolio-01.png"
import MTPortfolio02 from "images/mt_portfolio-02.png"
import MTPortfolio03 from "images/mt_portfolio-03.png"
import MTPortfolio04 from "images/mt_portfolio-04.png"
import MTPortfolio05 from "images/mt_portfolio-05.png"
import MTPortfolio06 from "images/mt_portfolio-06.png"
import MTPortfolio07 from "images/mt_portfolio-07.png"
import MTPortfolio08 from "images/mt_portfolio-08.png"

const Subheading = tw(SubheadingBase)`text-center`
const ImageContainer = tw.div`relative w-8/12 bg-primary-500 z-40 transform xl:-translate-x-16 xl:-translate-y-8`
const Image = tw.img`max-w-full w-full rounded-t sm:rounded relative z-20`
// const Image = tw.img`w-64`

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`

export default ({
  subheading = "We got big supports from",
  heading = "Our Portfolio",
}) => {
  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>

      </ContentWithPaddingXl>

      <ImageContainer>
        <br />
        <Image src={MTPortfolio01} />
        <Image src={MTPortfolio02} />
        <Image src={MTPortfolio03} />
        <Image src={MTPortfolio04} />
        <Image src={MTPortfolio05} />
        <Image src={MTPortfolio06} />
        <Image src={MTPortfolio07} />
        <Image src={MTPortfolio08} />
        <br />
      </ImageContainer>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  )
}
