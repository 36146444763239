import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { SectionHeading } from "components/misc/Headings.js"
import { SectionDescription } from "components/misc/Typography.js"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js"
import { ContentWithVerticalPadding } from "components/misc/Layouts.js"
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"
import TradeWithMachineIcon from "images/tech.png"

const Row = tw.div`flex flex-col lg:flex-row justify-center items-center lg:pt-8 max-w-screen-2xl mx-auto sm:px-8`
const Column = tw.div``
const TextColumn = tw(Column)`lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`
const Heading = tw(SectionHeading)`text-left text-gray-900 leading-snug xl:text-6xl`
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`
const FeatureList = tw.ul`mt-12 leading-loose`
const Feature = tw.li`flex items-center`
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`
const FeatureText = tw.p`ml-2 font-medium text-gray-700`
const ImageColumn = tw(Column)`relative w-full`
const ImageContainer = tw.div`relative w-full bg-primary-500 z-40 transform xl:-translate-x-16 xl:-translate-y-8`
const Image = tw.img`max-w-full w-full rounded-t sm:rounded relative z-20`
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`

export default ({
  heading = (<span>Starting with MINIMAL TECHNOLOGY</span>),
  description = "Let awesome technology minimize your work!",
  imageSrc = TradeWithMachineIcon,
  imageDecoratorBlob = true,
  primaryButtonUrl = "/#",
  primaryButtonText = "Get Started",
  buttonRounded = true,
  features = ["Design by MINIMAL in mind", "Develop by Professionals", "Optimize developing cost", "99.99% Uptime"],
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`

  return (
    <ContentWithVerticalPadding>
      <Row>
        <TextColumn>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
            {primaryButtonText}
          </PrimaryButton>
          <FeatureList>
            {features.map((feature, index) => (
              <Feature key={index}>
                <FeatureIcon />
                <FeatureText>{feature}</FeatureText>
              </Feature>
            ))}
          </FeatureList>
        </TextColumn>
        <ImageColumn>
          <ImageContainer>
            <Image src={imageSrc} />
            {imageDecoratorBlob && <ImageDecoratorBlob />}
          </ImageContainer>
          <Offsetbackground />
        </ImageColumn>
      </Row>
    </ContentWithVerticalPadding>
  )
}
