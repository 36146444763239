import React from "react"
import tw from "twin.macro"
import { ContentWithVerticalPadding } from "components/misc/Layouts.js"

const Row = tw.div`flex flex-col lg:flex-row justify-center items-center lg:pt-8 max-w-screen-2xl mx-auto sm:px-8`
const Column = tw.div``
const TextColumn = tw(Column)`lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`

export default () => {
  return (
    <ContentWithVerticalPadding>
      <Row>
        <TextColumn>
          Terms of Service now updating!
        </TextColumn>
      </Row>
    </ContentWithVerticalPadding>
  )
}
