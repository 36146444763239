import React from 'react'
import Homepage from './Homepage'
import Privacy from './legal/Privacy'
import Terms from './legal/Terms'

const Component = ({ currentPage, refMap }) => {
  switch (currentPage) {
    case '/terms-of-service': {
      return <Terms />
    }
    case '/privacy-policy': {
      return <Privacy />
    }
    default:{
      return <Homepage refMap={refMap} /> 
    }
  }
}

export default Component
