import React, { useState } from "react"
import { Popper, Paper, makeStyles } from '@material-ui/core'
import { motion } from 'framer-motion'

import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"

import PhatNguyenImg from "images/phat-nguyen.jpg"
import LJWImg from "images/lee-jae-woo.jpg"
import LHRImg from "images/lee-hyerin.jpg"
import MJKImg from "images/mj-kim.jpg"

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/2 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-48 h-48 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-2 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

const ProfileMap = {
  ['LEE JAE WOO']: null,
  ['LEE HERIN']: null,
  ['MJ KIM']: null,
  ['PHAT NGUYEN']: (
    <React.Fragment>
      <i>More detail:</i> <b><a href="https://phatnt.com" target="_blank" rel="noreferrer">https://phatnt.com</a></b>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(5),
      maxWidth: 800
    }
  }
})

export default ({
  cards = [
    {
      imageSrc: LHRImg,
      position: "CEO, Founder",
      name: "LEE HERIN",
      links: [],
    },
    {
      imageSrc: LJWImg,
      position: "Co-Founder",
      name: "LEE JAE WOO",
      links: [],
    },
    {
      imageSrc: MJKImg,
      position: "Project Manager, COO",
      name: "MJ KIM",
      links: [],
    },
    {
      imageSrc: PhatNguyenImg,
      position: "Chief Engineer, CTO",
      name: "PHAT NGUYEN",
      links: [],
    }
  ]
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [person, setPerson] = useState(null)

  return (
    <React.Fragment>
      <Cards>
        {cards.map((card, index) => (
          <Card key={index}
            onMouseEnter={(e) => {
              if (!ProfileMap[card.name]) {
                return
              }
              setAnchorEl(e.target)
              setPerson(card.name)
            }}
            onClick={(e) => {
              if (!ProfileMap[card.name]) {
                return
              }

              if (!anchorEl) {
                setAnchorEl(e.target)
                setPerson(card.name)
              } else {
                setAnchorEl(null)
                setPerson(null)
              }
            }}
          >
            <CardImage imageSrc={card.imageSrc} />
            <CardContent>
              <span className="position">{card.position}</span>
              <span className="name">{card.name}</span>
              <CardLinks>
                {card.links.map((link, linkIndex) => (
                  <a key={linkIndex} className="link" href={link.url}>
                    <link.icon className="icon" />
                  </a>
                ))}
              </CardLinks>
            </CardContent>
          </Card>
        ))}
      </Cards>

      <Popper
        onClick={() => {
          setAnchorEl(null)
          setPerson(null)
        }}
        open={anchorEl !== null} anchorEl={anchorEl} placement="left">
        <motion.div
          animate={{
            opacity: [0, 0.5, 1],
            y: [20, 10, 0]
          }}
          transition={{ duration: 0.2 }}
        >
          <Paper className={classes.paper}>
            {ProfileMap[person]}
          </Paper>
        </motion.div>
      </Popper>
    </React.Fragment>
  )
}
