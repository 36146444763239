import React from 'react'
import tw from "twin.macro"

import Hero from "components/hero/Hero"
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js"
import Features from "components/features/ThreeColWithSideImage.js"
// import Application from "components/features/IntroduceApplication.js"
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js"
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js"
import Testimonial from "components/testimonials/SimplePrimaryBackground.js"
import FAQ from "components/faqs/SingleCol.js"
import Partners from "components/testimonials/ThreeColumnWithProfileImage.js"

const HighlightedText = tw.span`text-primary-500`

const Component = ({ refMap }) => {
  return (
    <React.Fragment>
      <Hero />
      <FeatureStats ref={refMap.aboutRef} />
      <Features
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      />
      {/* <Application ref={refMap.productRef}
        heading={<>Q Trade <br /><HighlightedText style={{ fontSize: 18 }}>Professional Trading Application</HighlightedText></>}
      /> */}
      <MainFeature
        ref={refMap.teamRef}
        heading={<>Built by and for <HighlightedText>Professionals</HighlightedText></>}
      />
      <Testimonial ref={refMap.testimonialRef} />

      {/* <Pricing ref={refMap.pricingRef}
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      /> */}
      <Partners />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
    </React.Fragment>
  )
}

export default Component
