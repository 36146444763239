import React from "react"
import tw from "twin.macro"

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from "framer-motion"
import useInView from "use-in-view"

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`
function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>
  }

  if (!Array.isArray(children)) children = [children]

  const directions = ["left", "right"]
  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    )
  })
  return <>{childrenWithAnimation}</>
}

function AnimatedSlideInComponent({ children }) {
  const [ref] = useInView(30)

  return (
    <motion.section
      animate={{
        opacity: [0, 0.2, 0.5, 0.8, 1],
        y: [40, 30, 20, 10, 0]
      }}
      transition={{ duration: 0.5 }}
      ref={ref}
    >
      {children}
    </motion.section>
  )
}

export default props => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
)
