import React, { forwardRef } from "react"

import { ReactComponent as SouthKoreaIcon } from "images/south.svg"
import { ReactComponent as VietnamIcon } from "images/vietnam.svg"
import { ReactComponent as MailIcon } from "images/mail.svg"

import tw from "twin.macro"

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8`
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`

const ColumnHeading = tw.h5`font-bold uppercase`

const LinkList = tw.ul`mt-4 text-sm font-medium`
const LinkListItem = tw.li`mt-3`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`
const LogoText = tw.h5`ml-2 text-xl font-black`

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`

const FiveColumnDark = (props, ref) => {
  return (
    <Container ref={ref}>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoText>MINIMALTECH Ltd.,</LogoText>
            </LogoContainer>
            <CompanyAddress>
              <SouthKoreaIcon width={24} /> 331, Songnim-ro, Michuhol-gu, Incheon, Republic of Korea
            </CompanyAddress>

            <CompanyAddress>
              <VietnamIcon width={24} /> 21, Street No.4, An Phu An Khanh Urban Area, District 2, HCMC, Vietnam
            </CompanyAddress>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Who are we?</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/privacy-policy">Privacy Policies</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/terms-of-service">Terms of Service</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="tel:+821027000320" style={{ display: 'flex', alignItems: 'center' }}>
                  <SouthKoreaIcon width={24} />&nbsp;(+82) 10 2700 0320
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:+84942943199" style={{ display: 'flex', alignItems: 'center' }}>
                  <VietnamIcon width={24} />&nbsp;(+84) 942 943 199
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:herin@minimaltek.com" style={{ display: 'flex', alignItems: 'center' }}>
                  <MailIcon width={24} />&nbsp;Herin Lee
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:hello@minimaltek.com" style={{ display: 'flex', alignItems: 'center' }}>
                  <MailIcon width={24} />&nbsp;Customer Service
                </Link>
              </LinkListItem>
            </LinkList>

          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2022, MINIMALTECH Ltd.,</CopyrightNotice>
          <CompanyInfo>belive in algo</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  )
}

export default forwardRef(FiveColumnDark)
