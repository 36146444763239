import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import OurTeam from 'components/cards/ProfileThreeColGrid.js'
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg"
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg"
import { ReactComponent as EffectiveIcon } from "feather-icons/dist/icons/zap.svg"

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`

const FeatureHeadingContainer = tw.div`flex items-center`
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
  props.iconRoundedFull && tw`rounded-full`,
  props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
]}
  svg {
    ${tw`w-5 h-5`}
  }
`
const FeatureHeading = tw.div`ml-3 font-bold text-xl`

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`

export default React.forwardRef(({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = `
  With experienced Designers and IT developers, we are very confident and glad to announce our system.
  `,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}, ref) => {
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professionalism",
      description: "We have the best professional people across the globe just to work with you.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description: "We promise to offer you the best rate we can - at par with the industry standard.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    },
    {
      Icon: EffectiveIcon,
      title: "Effective",
      description: "We all ready to support you.",
      iconContainerCss: tw`bg-gray-300 text-gray-800`
    }
  ]

  if (!features) features = defaultFeatures

  return (
    <TwoColumn ref={ref}>
      <Column>
        <OurTeam />
      </Column>

      <TextColumn textOnLeft={true}>
        <TextContent>
          <Subheading>{subheading}</Subheading>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <Features>
            {features.map((feature, index) => (
              <Feature key={index}>
                <FeatureHeadingContainer>
                  <FeatureIconContainer
                    iconFilled={iconFilled}
                    iconRoundedFull={iconRoundedFull}
                    css={feature.iconContainerCss || iconContainerCss}
                  >
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureHeading>{feature.title}</FeatureHeading>
                </FeatureHeadingContainer>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </Feature>
            ))}
          </Features>
        </TextContent>
      </TextColumn>
    </TwoColumn>
  )
})
